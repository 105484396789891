<template>
  <v-sheet dark class="fill-height d-flex align-center justify-center">

    <div>
      <div class="d-flex justify-center mb-16">
        <h3 class="text-center">{{ is_westudy ? 'WeStudy' :  'Fine Trading' }} </h3>
      </div>
      <div class="d-flex justify-center">
        <v-progress-circular v-if="LOADING"  indeterminate size="48" />
      </div>
      <h1 class="font-weight-light text-center">{{ $t('Order') }} : {{ order }}</h1>
      <h1 class="font-weight-light text-center">{{ LOADING ? $t('a') : message }}</h1>

    </div>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PaymentRedirectLiqpay",
  props: ['alias','order'],
  data() {
    return {
      message : ''
    }
  },
  computed : {
    domain() {
      return location.host
    }
  },
  methods : {
    ...mapActions('avalon',['GET_BUSINESS_AVALON']),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapActions('monobank', ['GET_MONO_CHECKOUT_URL']),
    ...mapActions('fondy', ['GET_FONDY_CHECKOUT_URL']),
    ...mapActions('wayforpay', ['GET_WAYFORPAY_CHECKOUT_URL']),
    ...mapActions('stripe', ['GET_STRIPE_CHECKOUT_URL']),
    ...mapActions('portmone', ['GET_PORTMONE_CHECKOUT_URL']),


    async processOnlinePayment(order) {
      if ( !this.HAS_ONLINE_PAYMENT ) {
        console.log('!HAS_ONLINE_PAYMENT' , this.HAS_ONLINE_PAYMENT , this.$store.state.business.selectedBusiness.payments ,    this.$store.state.cart.payments)
        this.displayPurchase = false
        this.displayEnrollSuccess = true;
        return false
      }
      console.log('Checking payment system' , this.GET_ONLINE_PAYMENT)
      if ( this.GET_ONLINE_PAYMENT === 'online_liqpay') {
        return this.getLiqpayButton(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_mono') {
        return this.getMonoUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_fondy') {
        return this.getFondyUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_wayforpay') {
        console.log('Has way for pay')
        return this.getWayforpayUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_stripe') {
        this.getStripeUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_portmone') {
        this.getPortmoneUrl(order)
      }
    },

    async getMonoUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_MONO_CHECKOUT_URL(data)
      if ( !result ) {
        return result
      }
      let a = document.createElement('a')
      a.href = result.pageUrl
      a.click()
      a.remove()
      return true

    },
    async getWayforpayUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }
      console.log('getWayforpayUrl' , data)
      let result = await this.GET_WAYFORPAY_CHECKOUT_URL(data)
      if ( !result ) {
        return result
      }
      this.WAYFORPAY_INVOICE(result)
      return true

    },
    async getFondyUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_FONDY_CHECKOUT_URL(data)
      if ( !result ) {
        return result
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()
      return true

    },
    async getLiqpayButton(order) {

      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let button = await this.GET_BUSINESS_BUTTON(data)
      if ( !button || button === true ) {
        return false
      }

      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      this.displayEnrollSuccess = true
      return true
    },
    async getStripeUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_STRIPE_CHECKOUT_URL(data,'stripe')
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getPortmoneUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_PORTMONE_CHECKOUT_URL(data,'portmone')
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },

  },
  async mounted(){
    console.log('Order Redirect')
    await this.GET_BUSINESS_AVALON({ business : this.$store.state.domain ? location.host : this.alias , public : true})

    let data = {
        order_number: this.order,
    }
    console.log('Processing order' , data)

    this.loading = true
    let result = await this.processOnlinePayment(data)
    if ( !result ) {
      if ( this.$store.state.ajax.error === 'Order already payed') {
        this.message = this.$t('a')
      }
      if ( this.$store.state.ajax.error === 'Order not found') {
        this.message = this.$t('a')
      }
    }

  }
}
</script>

<style scoped>

</style>